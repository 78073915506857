<script lang="ts">
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';
import { defineComponent } from 'vue';

import Utility from '~/services/Utility';
import { useCommonStore } from '~/services/common';
import { SmallToast } from '~/services/common/component';
import { useCouponStore } from '~/services/coupon/store';
import { useInterestStore } from '~/services/interest';
import { useMainStore } from '~/services/main';
import { CompletePurchaseDialog } from '~/services/payment/component';
import { useUserProfileStore } from '~/services/profile';
import { usePwaStore } from '~/services/pwa/store';
import { useUserAuthStore } from '~/services/userAuth';

import MainPopupDialog from '~/components/common/MainPopupDialog.vue';
import AppFooter from '~/components/layouts/default/AppFooter.vue';
import AppHeader from '~/components/layouts/default/AppHeader.vue';
import AppHeaderBanner from '~/components/layouts/default/AppHeaderBanner.vue';

import LevelFloatingPopup from '../components/proflie/LevelFloatingPopup.vue';

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      menuActive: false,
      searchActive: false,
      alarmActive: false,
    };
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-menu-active': this.menuActive,
          'layout-search-active': this.searchActive,
          'layout-alarm-active': this.alarmActive,
        },
      ];
    },
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.searchActive = false;
      this.alarmActive = false;
      this.$toast.removeAllGroups();

      onErrorCaptured((err, vm, info) => {
        console.info('ERR: call captured in route--------', err, info);
        const is500ErrorMsg = err.toString().includes('Request failed with status code 500');
        if (is500ErrorMsg) {
          state.is500Error = true;
        }
        return false;
      });
      // console.info('ERR: after call captured in route--------');
    },
  },
  beforeUpdate() {
    if (this.searchActive) {
      this.addClass(document.body, 'body-overflow-hidden');
    } else {
      this.removeClass(document.body, 'body-overflow-hidden');
    }
  },
  methods: {
    onWrapperClick() {},
    menuDepthToggleMobile(event: Event) {
      sessionStorage.setItem('prevRoute', window.location.href);
      sessionStorage.setItem('prevPath', window.location.pathname);
      this.menuActive = !this.menuActive;

      sessionStorage.setItem('isToggled', `${this.menuActive}`);
      event.preventDefault();

      Utility.onChangeRoutingForChannelTalk(this.menuActive);
    },
    menuSearchToggleMobile(event: Event) {
      this.searchActive = !this.searchActive;
      event.preventDefault();
      Utility.onChangeRoutingForChannelTalk(this.searchActive);
    },
    menuAlarmToggleMobile(event: any, bool?: boolean) {
      if (bool !== undefined) {
        this.alarmActive = bool;
      } else {
        this.alarmActive = !this.alarmActive;
      }
      if (event.preventDefault) {
        event.preventDefault();
      }
      Utility.onChangeRoutingForChannelTalk(this.alarmActive);
    },
    addClass(element: Element, className: string) {
      if (element.classList) {
        element.classList.add(className);
      } else {
        element.className += ` ${className}`;
      }
    },
    removeClass(element: Element, className: string) {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(
          new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'),
          ' ',
        );
      }
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
  },
});
</script>

<script setup lang="ts">
const { isMobile } = useMobile();
const mainStore = useMainStore();
const couponStore = useCouponStore();
const commonStore = useCommonStore();
const pwaStore = usePwaStore();
const interestStore = useInterestStore();
const userAuthStore = useUserAuthStore();
const profileStore = useUserProfileStore();
const route = useRoute();

// 쿠폰 웰컴 팝업이 열렸었는지 체크, 열렸었다면 다른 팝업을 안열도록 처리
const isWelcomePopupOpened = ref(false);

// 관심사 팝업이 열렸었는지 체크, 열렸었다면 다른 팝업을 안열도록 처리
const isInterestPopupOpened = ref(false);

const {
  public: { app_env },
} = useRuntimeConfig();
if (app_env !== 'prod' && $isClient()) {
  window.openWelcome = () => couponStore.setWelcomePopup(true);
  window.openClassWelcome = () => couponStore.setClassWelcomePopup(true);
  window.openInterest = () => interestStore.setInterestPopup(true);
  window.openCompletePurchase = () => mainStore.setCompletePurchasePopup(true);
  window.openOpenAlarmGuide = () => mainStore.setOpenAlarmGuidePopup(true);
  window.openOpenAlarmResult = () => mainStore.setOpenAlarmResultPopup(true);
}

const INIT_STATE = () => ({
  is500Error: false,
});

const state = reactive<{ is500Error: boolean }>(INIT_STATE());

const isShowFooter = computed((): boolean => {
  const path = route.path;
  const mobileBlackList = ['/mypage', '/profile', '/search', '/wbtest'];
  const pcBlackList = ['/wbtest'];
  if (isMobile.value) {
    if (mobileBlackList.some((p) => path.startsWith(p))) {
      return commonStore.getMobileFooterViewList.includes(route.name);
    }
    return true;
  }
  return !pcBlackList.some((p) => path.startsWith(p));
});

watch(
  () => interestStore.getInterestPopupVisible,
  (val) => {
    if (val) {
      isInterestPopupOpened.value = true;
    }
  },
  { immediate: true },
);

watch(
  () => couponStore.getWelcomePopupVisible,
  (val) => {
    if (val) {
      isWelcomePopupOpened.value = true;
    }
  },
  { immediate: true },
);

watch(
  () => [route.path, userAuthStore.user],
  (path) => {
    if (!$isClient() || !userAuthStore.user) {
      return;
    }
    // 사용자 프로필 정보
    profileStore.fetchMyProfile();
  },
  {
    immediate: true,
  },
);

onMounted(() => {
  onErrorCaptured((err, vm, info) => {
    if (err instanceof AxiosError === false) {
      Sentry.captureException(err);
    }

    const is500ErrorMsg = err.toString().includes('Request failed with status code 500');
    if (is500ErrorMsg) {
      state.is500Error = true;
    }
    return false;
  });
});
</script>

<template>
  <div
    v-if="!state.is500Error"
    id="app-container"
    class="wb isolate"
    :class="[
      containerClass,
      { 'wb—mode-headersticky': mainStore.headerSticky },
      { 'wb—mode-pricesticky': mainStore.priceSticky },
      { 'wb—mode-tobbanner': mainStore.visibleTopBanner },
    ]"
    @click="onWrapperClick">
    <AppHeaderBanner />
    <AppHeader
      @menu-toggle="menuDepthToggleMobile"
      @search-toggle="menuSearchToggleMobile"
      @alarm-toggle="menuAlarmToggleMobile" />

    <div id="wb-contents" class="wb-contents">
      <slot />
    </div>

    <div id="app-footer">
      <AppFooter v-if="isShowFooter" :is-shown-depth-toggle="menuActive" />
    </div>
  </div>

  <Toast></Toast>

  <SmallToast />

  <ConfirmDialog></ConfirmDialog>

  <ClientOnly>
    <LevelFloatingPopup />
    <!-- 메인 팝업 컴포넌트 -->
    <MainPopupDialog />
    <!-- 결제완료 팝업 -->
    <CompletePurchaseDialog />
  </ClientOnly>
</template>

<style lang="scss" scoped>
.wb-contents {
  position: relative;
}
</style>
