<script setup lang="ts">
import InfiniteLoading from 'v3-infinite-loading';
import 'v3-infinite-loading/lib/style.css';

import IndexedDB from '~/libs/IndexedDB';
import Tracker from '~/libs/Tracker';

// import { useInterestStore } from '~/services/interest';
import { useNotification } from '~/services/user/notification/composable/useNotification';
import type { ClientUserNotification } from '~/services/user/notification/type';
import { useUserAuthStore } from '~/services/userAuth';

import { type PaginationOptions } from '~/types/global';

const PAGE_SIZE = 10;
const MAX_BADGE_COUNT = 99;

const emit = defineEmits<{
  (e: 'alarm-toggle', param: any, bool?: boolean): void;
}>();

const isAlarmReadShow = ref(false);
const router = useRouter();
const userAuthStore = useUserAuthStore();
const isMounted = useMounted();

const menuAlarm = ref();
const identifier = ref(0);

const { notifications, unreadCounts, hasNextPage, ...notiApi } = useNotification();
const badgeCounts = computed(() => (unreadCounts.value > MAX_BADGE_COUNT ? `${MAX_BADGE_COUNT}+` : unreadCounts.value));

const params: PaginationOptions = { pageNo: 1, pageSize: PAGE_SIZE };

const menuAlarmToggleMobile = (event: any) => {
  emit('alarm-toggle', event);
  // 티라노 TODO : 켜질 때만 호춯하도록 개선하자
  initNotifications();
};

const menuAlarmToggle = (event: any) => {
  menuAlarm.value.toggle(event);
  // 티라노 TODO : 켜질 때만 호춯하도록 개선하자
  initNotifications();
};

const initNotifications = () => {
  params.pageNo = 1;
  identifier.value++;
  nextTick(() => {
    notiApi.getNotifications(params, { reset: true });
    notiApi.getUnreadCounts();
  });
};

const getMoreNotification = async ($state: any) => {
  try {
    if (hasNextPage.value) {
      const nextPage = Math.ceil(notifications.value.length / params.pageSize) + 1;
      params.pageNo = nextPage;

      nextTick(async () => {
        await notiApi.getNotifications(params);

        $state.loaded();
      });
    } else {
      $state.complete();
    }
  } catch (error) {
    $state.error();
  }
};

const readAllNotification = () => {
  notiApi.readAll();
};

const readSingleNotification = async (item: ClientUserNotification) => {
  if (menuAlarm.value) {
    menuAlarm.value.hide(); // 알림 팝업 닫기
  }
  emit('alarm-toggle', {}, false);
  Tracker['Click Notification Center']({ notificationType: item.notificationCode });

  const { isRead, clickPath, clickType, title } = item;
  if (!isRead) {
    await notiApi.readSingle(String(item.id));
  }

  if (!clickPath || clickPath === '') {
    return;
  }

  switch (clickType) {
    case 'LINK':
      router.push(clickPath);
      break;

    case 'POPUP':
      // openPopup(clickPath);

      if (clickPath.includes('{USER_ID}') && title.includes('Lv')) {
        router.push(clickPath.replace('{USER_ID}', userAuthStore.user?.userId as any));
      }
      break;

    case 'TOAST':
      router.push(clickPath.replace('{USER_ID}', userAuthStore.user?.userId as any));
      break;
  }
};

// const openPopup = (popup: any) => {
//   const interestStore = useInterestStore();
//   switch (popup) {
//     case 'INTEREST_POPUP':
//       interestStore.setInterestPopup(true);
//       break;
//   }
// };

// PWA 앱푸시 용도 사용 추정
// 여기서 읽은걸 PWA에도 알리기 위함으로 판단됨
const checkIndexedDBForNotificationClickType = async () => {
  try {
    const notificationType = await IndexedDB.read('notificationType');
    if (notificationType) {
      Tracker['Click Notification']({
        notificationType,
      });
      IndexedDB.delete('notificationType');
    }
  } catch (err) {
    console.error(err);
  }
};

// PWA 앱푸시 용도 사용 추정
// PWA 에서 뜬 알람을 클릭하면 IndexDB에 저장되고, 이걸 꺼내서 쓰기 위함으로 판단
const checkIndexedDBForNotificationClick = async () => {
  const retrievedData = await IndexedDB.read('notificationclick');
  if (retrievedData) {
    await notiApi.readSingle(String(notifications.value[0].id));
    initNotifications();
    IndexedDB.delete('notificationclick');
    return true;
  }
  return false;
};

// PWA 앱푸시 용도 사용 추정
watch(
  () => notifications.value,
  () => checkIndexedDBForNotificationClickType(),
  { immediate: true },
);

// PWA 앱푸시 용도 사용 추정
watch(
  () => notifications.value,
  async () => {
    if (await checkIndexedDBForNotificationClick()) {
      return;
    }
    if (notifications.value.length === 0) {
      isAlarmReadShow.value = false;
      return;
    }

    // 알림이 없거나 알림을 가져온애들중 readYn이 N인게 없다면 false
    isAlarmReadShow.value = !!unreadCounts.value;
  },
  { deep: true, immediate: true },
);

// PWA 앱푸시 용도 사용 추정
watch(
  () => isAlarmReadShow,
  () => {
    if (isAlarmReadShow.value) {
      checkIndexedDBForNotificationClick();
    }
  },
  { deep: true },
);

const handleScroll = () => {
  menuAlarm.value?.hide();
};

window.addEventListener('scroll', handleScroll);
</script>

<template>
  <div class="wb-header__bell">
    <div class="pc">
      <Button class="overflow-visible" severity="secondary" outlined @click="menuAlarmToggle">
        <template #default>
          <i class="pi pi-bell alarm-icon">
            <Badge v-if="unreadCounts > 0" class="alarm-badge not-italic" severity="danger" :value="badgeCounts" />
          </i>
        </template>
      </Button>
    </div>
    <div class="mo">
      <Button class="overflow-visible" severity="secondary" outlined @click="menuAlarmToggleMobile">
        <template #default>
          <i class="pi pi-bell alarm-icon">
            <Badge v-if="unreadCounts > 0" class="alarm-badge not-italic" severity="danger" :value="badgeCounts" />
          </i>
        </template>
      </Button>
    </div>

    <Menu ref="menuAlarm" :popup="true" class="wb-header-alarm">
      <template #start>
        <div class="wb-header-alarm__head">
          <strong>알림</strong>
          <Button
            :disabled="isAlarmReadShow === false"
            label="전체읽음"
            link
            size="small"
            @click="readAllNotification" />
        </div>
      </template>
      <template #end>
        <div v-if="notifications.length !== 0" id="target_pc" class="wb-header-alarm-list">
          <NuxtLink
            v-for="item in notifications"
            :key="item.id"
            class="wb-header-alarm-list__item cursor-pointer"
            :prefetch="false"
            @click="readSingleNotification(item)">
            <div class="wb-header-alarm-list__item-text" :class="{ alarm_read: item.isRead }">
              <strong>{{ item.title }}</strong>

              <p class="noti_contents">
                {{ item.message }}
              </p>
            </div>
            <div class="wb-header-alarm-list__item-time" :class="{ alarm_read: item.isRead }">
              {{ item.ui_createdAt }}
            </div>
          </NuxtLink>
          <InfiniteLoading :identifier="identifier" :distance="0" target="#target_pc" @infinite="getMoreNotification">
            <template #spinner>
              <div class="flex justify-center p-[10px]">loading...</div>
            </template>
            <template #complete>
              <div class="hidden justify-center p-[10px]">더이상 알림이 없습니다.</div>
            </template>
          </InfiniteLoading>
        </div>

        <div v-if="notifications.length === 0" class="wb-header-alarm__none">알림이 없습니다.</div>
      </template>
    </Menu>

    <!-- 아래와 같이 사용시, emitsOptions 에러 메시지 발생 -->
    <!-- <Teleport v-if="isMounted" to="#wb-mobile-header-teleport"> -->
    <!-- Nuxt3 버전업 과정의 이슈로 보이며 app-header 로 붙도록 처리, 이후 Teleport 는 정리 필요 -->
    <Teleport v-if="isMounted" to="#app-container">
      <div class="wb-header-alarm-mobile">
        <div class="wb-header-alarm__button">
          <Button label="알림" severity="secondary" link icon="pi pi-times" @click="menuAlarmToggleMobile" />
        </div>
        <div class="wb-header-alarm__head">
          <strong>알림</strong>
          <Button :disabled="!isAlarmReadShow" label="전체읽음" link @click="readAllNotification" />
        </div>
        <div v-if="notifications.length !== 0" id="target_mobile" class="wb-header-alarm-list">
          <NuxtLink
            v-for="item in notifications"
            :key="item.id"
            class="wb-header-alarm-list__item cursor-pointer"
            @click="readSingleNotification(item)">
            <div class="wb-header-alarm-list__item-text" :class="{ alarm_read: item.isRead }">
              <strong>{{ item.title }}</strong>
              <p>{{ item.message }}</p>
            </div>
            <div class="wb-header-alarm-list__item-time" :class="{ alarm_read: item.isRead }">
              {{ item.ui_createdAt }}
            </div>
          </NuxtLink>
          <InfiniteLoading
            :identifier="identifier"
            :distance="0"
            target="#target_mobile"
            @infinite="getMoreNotification">
            <template #spinner>
              <div class="flex justify-center p-[10px]">loading...</div>
            </template>
            <template #complete>
              <div class="hidden justify-center p-[10px]">더이상 알림이 없습니다.</div>
              <!-- ???? -->
            </template>
          </InfiniteLoading>
        </div>
        <div v-if="notifications.length === 0" class="wb-header-alarm__none">알림이 없습니다.</div>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.alarm_read {
  opacity: 0.3;
}

.noti_contents {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alarm-icon {
  position: relative;
  width: 24px;
  height: 24px;
}

.alarm-badge {
  position: absolute;
  top: -5px;
  left: 12px;
  font-size: 11px;
  margin-left: 0px;
  padding: 0px 5px;
  z-index: 1;
}
</style>
