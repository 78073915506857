<script setup lang="ts">
import Tracker from '~/libs/Tracker';

import { useCommonStore } from '~/services/common';
import PopularSearches from '~/services/search/components/PopularSearches.vue';
import RecentKeywords from '~/services/search/components/RecentKeywords.vue';
import SearchInput from '~/services/search/components/SearchInput.vue';
import { useSearchPathSuffix } from '~/services/search/utils/useSearchPathSuffix';

import ArrowNarrowLeftIcon from '~/components/common/Icons/ArrowNarrowLeftIcon.vue';

const route = useRoute();
const router = useRouter();
const commonStore = useCommonStore();
const emit = defineEmits<{ (e: 'search-toggle', param: any): void }>();
const menuSearch = ref<any>(null);
const keyword = ref<string>((route.params.keyword as string) || '');
const searchInputContainerRef = ref();
const searchInputContainerMobileRef = ref();
const { isDesktop } = useMobile();

const getPathWithSuffix = useSearchPathSuffix();

const isSearchPanelOpen = ref(false);

const menuSearchToggle = (event: MouseEvent) => {
  menuSearch.value.toggle(event);
  searchInputContainerRef.value?.searchInputRef.$el?.focus();
};

const menuSearchToggleMobile = (event: any) => {
  emit('search-toggle', event);
  isSearchPanelOpen.value = !isSearchPanelOpen.value;
};

const afterSearch = (value: string) => {
  // 포커스 해제
  searchInputContainerRef.value?.searchInputRef.$el?.blur();
  searchInputContainerMobileRef.value?.searchInputRef.$el?.blur();
  // 선택한 검색어를 입력필드에 적용
  keyword.value = value;
  // 메뉴 숨김
  menuSearch.value.hide();
  isSearchPanelOpen.value = false;
  // 최근 검색어 추가
  commonStore.appendSearchKeyword(value);
  // 검색 로깅
  Tracker['Click Search Keyword'](keyword.value);
};

const onSearchKeyword = () => {
  if (keyword.value === '') return;
  afterSearch(keyword.value);
  router.push(getPathWithSuffix(`/search/${encodeURIComponent(keyword.value)}`));
};

function handleScroll() {
  menuSearch.value?.hide();
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

watch(
  () => route.params.keyword,
  (newKeyword) => {
    keyword.value = newKeyword as string;
  },
);
</script>
<template>
  <div>
    <!-- 데탑 검색 입력필드 -->
    <SearchInput
      v-if="isDesktop"
      ref="searchInputContainerRef"
      v-model:value="keyword"
      @click="menuSearchToggle"
      @searchSubmit="onSearchKeyword" />

    <!-- 모바일 검색 버튼 -->
    <div v-if="!isDesktop" class="w-10 h-10 flex items-center justify-center">
      <MagnifierIcon role="button" @click="menuSearchToggleMobile" />
    </div>

    <!-- 데탑용 검색화면 -->
    <OverlayPanel ref="menuSearch" class="no-bubble" append-to="body">
      <div class="flex">
        <div style="width: 174px" class="py-5 px-6 border-r border-neutral-200">
          <RecentKeywords @click:tag="afterSearch" />
        </div>
        <div style="width: 200px" class="py-5 px-6">
          <PopularSearches @click:item="afterSearch" />
        </div>
      </div>
    </OverlayPanel>

    <!-- 모바일용 검색 화면 -->
    <Dialog v-if="!isDesktop" class="wb-dialog" :visible="isSearchPanelOpen">
      <div class="flex items-center justify-start py-1 px-4 gap-4">
        <ArrowNarrowLeftIcon role="button" class="cursor-pointer" @click="menuSearchToggleMobile" />
        <SearchInput
          ref="searchInputContainerMobileRef"
          v-model:value="keyword"
          autofocus
          @searchSubmit="onSearchKeyword" />
      </div>
      <div class="overflow-y-auto px-4 py-5" style="max-height: calc(100svh - 54px)">
        <RecentKeywords @click:tag="afterSearch" />
        <hr class="border-neutral-200 my-6" />
        <PopularSearches @click:item="afterSearch" />
      </div>
    </Dialog>
  </div>
</template>
