import { getTimeDifference } from '~/libs/dateUtils';

import BaseService from '~/services/BaseService';

import type { ApiResponse, PaginationData, PaginationOptions } from '~/types/global';

import type { ClientUserNotification, UserNotification, UserNotificationCounts } from './type';

class NotifcationService extends BaseService {
  constructor() {
    super('/user/notifications');
  }

  getNotificationCounts() {
    return this.getV2<ApiResponse<{ items: UserNotificationCounts }>>('/counts');
  }

  getNotifications(params: Pick<PaginationOptions, 'pageNo' | 'pageSize'>) {
    return this.getV2<ApiResponse<PaginationData & { items: UserNotification[] }>>('', { params });
  }

  readNotification(params: { id: string | number }) {
    return this.patchV2(`/${params.id}`, { body: { isRead: true } });
  }
  readAllNotification() {
    return this.patchV2('/read-all');
  }

  parseNotifications(notifications: UserNotification[]): ClientUserNotification[] {
    return notifications.map((item) => ({
      ...item,
      ui_createdAt: getTimeDifference(item.createdAt),
    }));
  }
}

export const notificationService = new NotifcationService();
