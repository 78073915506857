import { uniqBy } from 'lodash-es';

import { notificationService } from '~/services/user/notification/service';
import { type ClientUserNotification } from '~/services/user/notification/type';
import { useUserAuthStore } from '~/services/userAuth';

import type { PaginationOptions } from '~/types/global';

export const useNotification = () => {
  const userAuthStore = useUserAuthStore();
  const route = useRoute();

  const hasNextPage = ref(true);
  const notifications = ref<ClientUserNotification[]>([]);
  const unreadCounts = ref(0);

  const getUnreadCounts = async () => {
    const { data } = await notificationService.getNotificationCounts();
    unreadCounts.value = data?.items?.notificationCount || 0;
  };

  const getNotifications = async (params: PaginationOptions, options: { reset: boolean } = { reset: false }) => {
    const { data } = await notificationService.getNotifications(params);

    if (options?.reset) {
      notifications.value = notificationService.parseNotifications(data.items);
    } else {
      notifications.value = notificationService.parseNotifications(
        uniqBy([...notifications.value, ...data.items], 'id'),
      );
    }

    hasNextPage.value = data.paging.hasMore;
  };

  const readSingleNotification = async (id: string) => {
    await notificationService.readNotification({ id });
    getNotifications({ pageNo: 1, pageSize: notifications.value.length }, { reset: true });
    getUnreadCounts();
  };

  const readAllNotification = async () => {
    await notificationService.readAllNotification();
    getNotifications({ pageNo: 1, pageSize: notifications.value.length }, { reset: true });
    getUnreadCounts();
  };

  // 로그인 & 페이지 이동 때 재 호출
  watch(
    () => [route.fullPath, userAuthStore.user],
    () => getUnreadCounts(),
    { deep: true, immediate: true },
  );

  return {
    notifications,
    unreadCounts,
    hasNextPage,
    getUnreadCounts: getUnreadCounts,
    getNotifications: getNotifications,
    readSingle: readSingleNotification,
    readAll: readAllNotification,
  };
};
